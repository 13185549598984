<template>
  <div>
    <div class='form-group'>
      <label :for="id" v-html="label" class="mb-2"></label>
      <div :class="['input-file-dropbox', modelValue ? 'input-file-dropbox-success' : '']">
        <input
            :id='id'
            class='form-control input-file'
            type='file'
            @input='handleInput'
        />

        <p v-if='modelValue' class='overline pb-0 mb-0'>
          <i class='material-icons-outlined animate fadeIn mdc-18 me-1'>check</i>{{ modelValue ? modelValue.name : '' }}
        </p>
        <p v-else class='overline pb-0 mb-0'><i class='material-icons-outlined mdc-18 me-1'>add_circle</i>
          Upload an image
        </p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    modelValue: {
      default: null,
    }
  },
  data() {
    return {
      content: this.modelValue
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', e.target.files[0])
    }
  }
}
</script>
