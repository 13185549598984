<template>
  <div>
    <div class="sidebar" :class="[isSidebarOpen ? 'sidebar-hidden' : '']">
      <div class="sidebar-content">
        <div class="sidebar-header">
          <div>
            <img v-if="currentUser.photoURL"
                 :src="currentUser.photoURL"
                 :alt="currentUser.displayName + ' Photo'"
                 :title="currentUser.displayName + ' Photo'"
                 class="img-fluid border-radius-50 mb-2"
                 width="50" height="50">
            <i v-else class="material-icons-outlined mdc-48">&#xe853;</i>

            <p class="overline text-muted text-truncate mb-0" style="max-width: 150px">
              {{ currentUser.displayName || "Loading..." }}
            </p>
          </div>
          <button class="btn btn-link btn-sm ripple ripple-primary m-0"
                  title="Close Sidebar"
                  @click="sidebarToggle()">
            <i class="material-icons-outlined">&#xe5dc;</i>
          </button>
        </div>
        <div class="sidebar-body">
          <p class="overline text-muted my-3">RESTAURANT</p>
          <router-link :to="{name: 'Home'}" class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xe871;</i>
            Dashboard
          </router-link>
          <router-link :to="{name: 'Locations'}" class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xea12;</i>
            Restaurants locations
          </router-link>

          <span v-if="!isMenuMakerEnabled"
                class="d-grid"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Create a restaurant location to edit your menus">
            <router-link :to="{name: 'MenuTab', params: { tab: 'categories' }}"
                         :class="['sidebar-item', 'disabled']">
              <i class="material-icons-outlined me-2">&#xf206;</i>
              Menu maker
            </router-link>
          </span>
          <router-link v-else
                       :to="{name: 'MenuTab', params: { tab: 'categories' }}"
                       class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xf206;</i>
            Menu maker
          </router-link>

          <router-link :to="{name: 'QRCodeGenerator'}" class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xef6b;</i>
            Your QR code
          </router-link>

          <p class="overline text-muted my-3">ADMINISTRATION</p>

          <router-link :to="{name: 'Settings'}" class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xe8b8;</i>
            Settings
          </router-link>

          <router-link :to="{name: 'Account'}" class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xe853;</i>
            Account
          </router-link>

          <router-link :to="{name: 'Billing'}" class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xf041;</i>
            Billing
          </router-link>

          <!--
          <router-link :to="{name: 'Hours'}" class="sidebar-item">
                      <i class="material-icons-outlined me-2">&#xe8b5;</i>
                      Hours
                    </router-link>
          -->

          <p class="overline text-muted my-3">SUPPORT</p>
          <a href="mailto:contact@menuffy.com" class="sidebar-item">
            <i class="material-icons-outlined me-2">&#xe8fd;</i>
            Help
          </a>

          <hr class="mb-2">

          <p class="overline text-muted my-3">THEME</p>
          <div>
            <input type="checkbox" class="btn-check" id="btn-check-nav" autocomplete="off" v-model="darkMode">
            <label class="fw-normal w-100 sidebar-item ripple ripple-primary" for="btn-check-nav">
              <i class="material-icons-outlined me-2" :class="[ darkMode ? 'text-warning' : 'text-muted']">
                {{ darkMode ? '&#xe3aa;' : '&#xe51c;' }}</i>
              {{ darkMode ? 'Light mode' : 'Dark mode' }}
            </label>
          </div>

        </div>
        <div class="sidebar-footer">
          <button class="btn btn-default-outline btn-micint-x ripple ripple-secondary w-100" @click="signOut">
            Sign out
            <i class="material-icons-outlined">&#xe879;</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {signOut} from "firebase/auth";
import {firebaseAuth, db} from "@/firebase";
import {mapGetters} from "vuex";
import {Tooltip} from 'bootstrap'
import {collection, getDocs} from 'firebase/firestore'

export default {
  name: "TheSidebar",
  props: {
    isSidebarActive: {
      type: Boolean,
      default: null
    },
    sidebarToggle: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      currentUser: firebaseAuth.currentUser,
      isMenuMakerEnabled: false,
    }
  },
  mounted() {
    this.enableTooltips()
    this.toggleMenuMakerButton()
  },
  methods: {
    enableTooltips() {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
      })
    },
    async toggleMenuMakerButton() {
      const locationsRef = collection(db, 'users', firebaseAuth.currentUser.uid, 'restaurantLocations')
      this.isMenuMakerEnabled = await getDocs(locationsRef).then(querySnap => querySnap.docs.length > 0)
    },
    signOut() {
      signOut(firebaseAuth)
          .then(() => this.$router.replace({name: 'SignIn'}))
    }
  },
  computed: {
    ...mapGetters(['isSidebarOpen']),
    darkMode: {
      get() {
        return this.$store.getters.darkMode
      },
      set(newValue) {
        this.$store.commit("SET_DARK_MODE", newValue)
      }
    }
  }
}
</script>
