<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
    <div class="container">
      <div>
        <button class="btn btn-link ripple ripple-primary me-2" @click="sidebarToggle" title="Menu Sidebar">
          <i class="material-icons-outlined">&#xe5c3;</i>
        </button>
        <router-link :to="{name: 'Home'}" class="navbar-brand">
          <img src="@/assets/menuffy-logo.svg" class="img-fluid me-2" width="35" height="35" alt="Menuffy Logo">
          <span>Partners</span>
        </router-link>
      </div>
    </div>
    <TheSidebar
        :is-sidebar-active="isSidebarActive"
        :sidebar-toggle="sidebarToggle"/>
  </nav>
</template>

<script>
import TheSidebar from "@/components/TheSidebar";
import {getCurrentUser} from "@/firebase";

export default {
  name: "TheNavbar",
  components: {TheSidebar},
  data() {
    return {
      isSidebarActive: false,
      currentUser: getCurrentUser()
    }
  },
  methods: {
    sidebarToggle() {
      this.$store.dispatch('toggleClassSidebar')
    }
  }
}
</script>
